<template>
    <div id="product">
        <!-- Error -->
        <section v-if="!product" id="information">
            <div class="section-content section-content-fullscreen style-basic">
                <div class="container h-100">
                    <div class="row h-100">
                        <div class="col-12 mt-auto mb-0">
                            <p class="title text-center">Ошибка</p>
                        </div>

                        <div class="col-12 mb-auto">
                            <p class="color-basic-text text-center mt-0">
                                Такое декоративное покрытие не существует.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Product Main Information -->
        <header v-if="product">
            <div class="section-content style-basic">
                <div class="container">
                    <div class="row">
                        <!-- Images -->
                        <div class="col-12 col-md-7 mb-5 mb-md-0">
                            <div class="avatar">
                                <img class="img-fluid" ref="selectedImg">
                            </div>
                            <div class="images">
                                <img v-for="image in images" :key="image" class="img-fluid" v-on:click="chooseImage" v-lazy="image">
                            </div>
                        </div>

                        <!-- Information -->
                        <div class="col-12 col-md-5">
                            <h1 class="styled-header font-weight-bold">
                                {{product.name}}
                            </h1>

                            <p class="h5 color-basic-text">
                                {{product.shortDescription}}
                            </p>

                            <p class="color-basic-text">
                                ~ {{ pricePerMeter }} ₽ м<sup>2</sup>
                            </p>

                            <ul>
                                <li v-for="advantage in product.advantages" :key="advantage">{{advantage}}</li>
                            </ul>

                            <form method="GET" action="/buy" @submit.stop.prevent="submit()">
                                <div ref="additionalOptions"></div>
                                <div class="meters-input">
                                    <label>
                                        м <sup>2</sup>
                                    </label>

                                    <input type="number" ref="meters" name="meters" placeholder="0" min="1" required class="form-control style-basic border-md">

                                    <button type="submit" class="btn style-secondary-active default$">Рассчитать цену</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <!-- Full Description, etc -->
        <section v-if="product" id="information">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <p class="title">Подробнее о штукатурке "{{product.nameru}}"</p>

                    <p class="color-basic-text">
                        {{product.description}}
                    </p>

                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" style="width:25%">Единица</th>
                                <th scope="col" style="width:75%">Цена</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(value,name) in product.priceBuy" :key="name">
                                <td scope="row">{{name.replace('kg','кг')}}</td>
                                <td>{{value}} ₽</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <p class="h5 color-basic-text mt-0">
                        Расход: {{product.consumption}}кг на м<sup>2</sup>
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Product',
    methods: {
        getProduct() {
            //GET param 'id'
            var productId = null, tmp = [];
            location.search
                .substr(1)
                .split("&")
                .forEach(function (item) {
                    tmp = item.split("=");
                    if (tmp[0] === 'id') productId = decodeURIComponent(tmp[1]);
                });

            //if has 'id' param
            if(productId != undefined) {
                return window.data.products.filter(product => product.id == productId)[0]
            }
            else
                return undefined;
        },

        getImage: function(imgNumber) {
            return require(`../assets/img/products/${this.product.id}/${imgNumber}.jpeg`)
        },

        selectImage(src) {
            this.$refs.selectedImg.src = src;
        },

        chooseImage(event) {
            this.selectImage(event.target.getAttribute('src'))
        },

        submit() {
            let additionalParams = '';
            
            //options
            if(this.product.options?.length > 0) {
                let optionName = document.querySelector('[name="option"]:checked')?.value
                if(optionName && optionName != 'none')
                    additionalParams += `&opt=${optionName}`
            }

            //TODO: error handling
            this.$router.push(`/buy?id=${this.product.id}&meters=${this.$refs.meters.value}${additionalParams}`);
        }
    },
    mounted: function() {
        this.selectImage(this.getImage('01'))

        //options
        if(this.product.options) {
            this.$refs.additionalOptions.innerHTML += `
                    <div class="form-check form-control-lg">
                        <input class="form-check-input" type="radio" name="option" value="none" checked="true" id="noneOption">
                        <label class="form-check-label" for="noneOption">
                            Без доп. материала
                        </label>
                    </div>
                `;

            this.product.options.forEach(optionName => {
                var option = window.data.options.filter((option) => option.name == optionName)[0];

                this.$refs.additionalOptions.innerHTML += `
                    <div class="form-check form-control-lg">
                        <input class="form-check-input" type="radio" name="option" value="${option.name}" id="${option.name}">
                        <label class="form-check-label" for="${option.name}">
                            ${option.nameru} <span>(~ ${option.estimatedPrice} &#8381;/м<sup>2</sup>)</span>
                        </label>
                    </div>
                `;
            })
        }

        window.scrollTo(0,0);
    },
    computed: {
        images: function() {
            var result = []

            for(var i = 1; i <= this.product.images; i++) {
                var imgName = i + '.jpeg'
                if(i < 10)
                    imgName = '0' + i +'.jpeg'

                result.push(require(`../assets/img/products/${this.product.id}/${imgName}`))
            }

            return result
        },
        pricePerMeter() {
            // find min price
            const minQuantityPrice = Math.min(...Object.values(this.product.priceBuy))
        
            // find quantity by min price
            let minQuantity = '?кг'
            Object.keys(this.product.priceBuy).forEach(q => {
                if(this.product.priceBuy[q] == minQuantityPrice) {
                    minQuantity = q;
                    return;
                }
            })
            const minQuantityRaw = parseInt(minQuantity.replace('kg', ''));

            // calculate price per square meter
            return Math.trunc((minQuantityPrice / minQuantityRaw) * this.product.consumption);
        }
    },
    data: function() {
        return {
            product: this.getProduct()
        }
    }
}
</script>
